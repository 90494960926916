import { styled } from 'styled-system/jsx'
import { Cross2Icon } from '@radix-ui/react-icons'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { ComponentProps, ReactNode } from 'react'
import { css, cva } from 'styled-system/css'

type Align = 'center' | 'end' | 'start' | undefined

export type PopoverContentProps = ComponentProps<typeof PopoverPrimitive.Content>
const Popover = ({
    children,
    trigger,
    align,
    open,
    setOpen,
    ...props
}: { children: ReactNode; containerRef?: any, trigger: ReactNode; align?: Align; open?: boolean; setOpen?: (x: boolean) => void } & PopoverContentProps) => {
    return (
        <PopoverPrimitive.Root open={open} onOpenChange={setOpen}>
            <PopoverPrimitive.Trigger asChild>{trigger}</PopoverPrimitive.Trigger>
            <PopoverContent
                // collisionPadding={12}
                // align={align} sideOffset={5}
                {...props}
            >
                {children}
                <PopoverClose aria-label="Close">
                    <Cross2Icon />
                </PopoverClose>
            </PopoverContent>
        </PopoverPrimitive.Root>
    )
}

export default Popover

const styledContent = cva({
    base: {
        borderRadius: 4,
        padding: 20,
        width: 'max-content',
        zIndex: 400,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '$gray1',
        boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
        '@media (prefers-reduced-motion: no-preference)': {
            animationDuration: '400ms',
            animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
            willChange: 'transform, opacity',
            '&[data-state="open"]': {
                '&[data-side="top"]': {
                    animationName: 'slideDownAndFade',
                },
                '&[data-side="right"]': {
                    animationName: 'slideLeftAndFade',
                },
                '&[data-side="bottom"]': {
                    animationName: 'slideUpAndFade',
                },
                '&[data-side="left"]': {
                    animationName: 'slideRightAndFade',
                },
            },
        },
        '&:focus': {
            boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px, 0 0 0 2px colors.$primary7',
        },
    },
})

function PopoverContent({ children, className, containerRef, ...props }: any) {
    return (
        <PopoverPrimitive.Portal container={containerRef?.current}>
            <PopoverPrimitive.Content sticky="always" className={styledContent()} {...props}>
                {children}
                <PopoverPrimitive.Arrow className={css({ fill: '$loContrast' })} />
            </PopoverPrimitive.Content>
        </PopoverPrimitive.Portal>
    )
}

const StyledClose = styled(PopoverPrimitive.Close, {
    base: {
        all: 'unset',
        fontFamily: 'inherit',
        borderRadius: '100%',
        height: 25,
        width: 25,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '$primary11',
        position: 'absolute',
        top: 5,
        right: 5,
        '&:hover': {
            backgroundColor: '$primary4',
        },
        '&:focus': {
            boxShadow: '0 0 0 2px colors.$primary7',
        },
    },
})

// Exports
export const PopoverClose = StyledClose
